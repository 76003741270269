import { generateCookieData } from '@redux/rfq/utils';
import { keys } from '@utils/objectUtils';
import { isBefore } from 'date-fns';
import { differenceInHours } from 'date-fns';
import { CategoryCode } from 'types/Category';
import { Membership } from 'types/redux';
import { InlineFieldKeys, InlineFieldShape, InlineFields } from 'types/rfq';
import { valid } from '../../../../utils/emailValidationHelper';
import { validate10Digits } from '../../../../utils/validate';
import localStorageHelper from './data/loader/local_storage';
import type { UseInlineRfqFormReturn } from './hooks/useInlineRfqForm/useInlineRfqForm';
import guestCountConfig from './rfqConfig/guestCount';

export const ERRORS: Rfq.InlineDefaults = {
	emailAddress: 'Please enter a valid email address.',
	firstName: 'Please enter at least 2 characters.',
	guestCount: 'Please choose your estimated guest count.',
	lastName: 'Please enter at least 2 characters.',
	phoneNumber: 'Please enter 10 digits.',
	textarea: 'Please add your message.',
	weddingDate: 'Please add your wedding date.',
};

export const SENTENCE_CASE_LABELS: Rfq.InlineDefaults = {
	emailAddress: 'Email',
	firstName: 'First name',
	guestCount: 'Number of guests',
	lastName: 'Last name',
	phoneNumber: 'Phone number',
	textarea: 'Introduce yourself and share your wedding vision',
	weddingDate: 'Wedding date',
};

export const DEFAULT_EMAIL_DOMAIN_SUGGESTIONS = [
	'gmail.com',
	'yahoo.com',
	'hotmail.com',
	'icloud.com',
	'outlook.com',
	'aol.com',
];

export const generateDefaultField = (
	name: Rfq.InlineFieldKeys,
): Rfq.InlineFieldShape => {
	if (name === 'phoneNumber') {
		return {
			error: '',
			label: SENTENCE_CASE_LABELS[name],
			state: 'neutral',
			value: '',
		};
	}

	return {
		error: ERRORS[name],
		label: SENTENCE_CASE_LABELS[name],
		state: 'invalid',
		value: '',
	};
};

const getFormValidationError = (name: Rfq.InlineFieldKeys, value: string) => {
	if (name === 'emailAddress') {
		return validateEmail(value);
	}
	if (name === 'firstName' || name === 'lastName') {
		return validateName(value);
	}
	if (name === 'weddingDate') {
		const isBeforeDate = isBefore(new Date(), new Date(value));
		return validateDate(value, isBeforeDate);
	}
	if (name === 'phoneNumber') {
		const isValid = validate10Digits(value);
		return isValid ? '' : ERRORS.phoneNumber;
	}

	return validateNotEmpty(value, name);
};

export const handleFieldChange: Rfq.HandleFieldChange = ({
	cb,
	data,
	name,
	value,
}) => {
	const error = getFormValidationError(name, value);
	const isError = error !== '';
	const field: Rfq.InlineFieldShape = {
		...data,
		error: isError ? error : '',
		state: isError ? 'invalid' : 'neutral',
		value,
	};
	cb(field, name);
};

export const formatPrice = (price = -1) => {
	return price >= 0
		? price
				.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
				.replace('.00', '')
		: null;
};

export const isOutsideRange = (date: Date) =>
	differenceInHours(date, new Date()) < 0;

export const onDateFieldClick = (
	isDatePickerOpen: boolean,
	cb: (status: boolean) => void,
) => {
	if (!isDatePickerOpen) {
		cb(true);
	}
};

export const validateDate = (value: string, isBefore: boolean) =>
	value.length && isBefore ? '' : ERRORS.weddingDate;

export const validateEmail = (value: string) =>
	valid(value) ? '' : ERRORS.emailAddress;

export const validateName = (value: string) =>
	value.length < 2 ? ERRORS.firstName : '';

export const validateNotEmpty = (value: string, name: Rfq.InlineFieldKeys) =>
	value.length ? '' : ERRORS[name];

export const isPostPrimary = (
	conversations: MessagedVendors.Conversations,
	vendorId: Vendor.Raw['id'],
	isInlineFormOpen: boolean,
	showErrors: boolean,
	areErrorsInForm: boolean,
) =>
	!!conversations[vendorId] ||
	(!isInlineFormOpen && showErrors && !areErrorsInForm);

export const checkIsTablet = (viewport: Redux.Viewport): boolean => {
	if (viewport) {
		const { isTablet, greaterThan, lessThan } = viewport;
		return isTablet || (lessThan?.medium && greaterThan?.extraSmall);
	}

	return false;
};

export const determineCategoryCopy = (code: Category.CategoryCode) => {
	return code === 'BEA' ? 'Schedule Appointment' : 'Request Quote';
};

export interface DetermineButtonTextArgs {
	code: Category.CategoryCode;
	isMobile: boolean;
	isTablet: boolean;
	isSubmitting?: boolean;
}

export const determineButtonText = (args: DetermineButtonTextArgs) => {
	const { code, isMobile, isTablet, isSubmitting } = args;

	if (isSubmitting) {
		return 'Sending request...';
	}

	if (code === 'BEA') {
		return determineCategoryCopy(code);
	}

	return isMobile || isTablet ? 'Submit' : determineCategoryCopy(code);
};

export type SourceContent = 'inline RFQ CTA pricing' | 'inline RFQ pricing top';

export interface ResolveButtonText {
	catCode: Category.CategoryCode;
	isMobile: boolean;
	isTablet: boolean;
}

export const resolveButtonText = ({
	catCode,
	isMobile,
	isTablet,
}: ResolveButtonText) => {
	if (catCode === 'BEA') {
		return 'Schedule Appointment';
	}
	if (!isMobile && !isTablet) {
		return 'Request Quote';
	}
	return 'Submit';
};

export const getGuestCountOptions = () =>
	guestCountConfig.property.values.map((value) => value.display);

export const getGuestCountOptionsValues = () =>
	guestCountConfig.property.values.map((value) => value.value);

export const getMaxGuestOption = () =>
	guestCountConfig.property.values[guestCountConfig.property.values.length - 1];

export const loadStoredRfqFormData = ({
	context,
	categoryCode,
	membership,
}: {
	context: UseInlineRfqFormReturn;
	categoryCode: CategoryCode;
	membership: Membership;
}) => {
	const cookie: Rfq.Cookie =
		localStorage !== undefined ? localStorageHelper.get() : {};
	const loadedValues: InlineFields = <InlineFields>{};
	const callback = (field: InlineFieldShape, name: InlineFieldKeys) => {
		loadedValues[name] = field;
	};
	const { isDateFlexible, ...fieldValues } = context.values;

	if (keys(cookie).length === 0) {
		if (membership?.member) {
			loadFieldsFromMembership(membership, context.values, callback);

			context.setInitialValues({
				...loadedValues,
				isDateFlexible,
			});
		}

		return;
	}

	const { cookieData, flexibleWeddingDate } = generateCookieData(
		cookie,
		categoryCode,
	);

	keys(fieldValues).forEach((key) =>
		loadFieldFromCookie(key, cookieData, fieldValues, callback),
	);

	context.setInitialValues({
		...loadedValues,
		isDateFlexible: flexibleWeddingDate || false,
	});
};

const loadFieldsFromMembership = (
	membership: Redux.Membership,
	fields: Rfq.InlineFields,
	callback: (field: InlineFieldShape, name: InlineFieldKeys) => void,
) => {
	const { analyticsProperties } = membership;
	if (analyticsProperties?.memberProperties) {
		const memberProps = {
			emailAddress: analyticsProperties.memberProperties.email || '',
			firstName: analyticsProperties.memberProperties.firstName || '',
			guestCount: analyticsProperties.memberProperties.weddingSize || '',
			lastName: analyticsProperties.memberProperties.lastName || '',
			weddingDate: analyticsProperties.memberProperties.weddingDate || '',
		};

		keys(memberProps).forEach((key) => {
			if (memberProps[key]) {
				handleFieldChange({
					cb: callback,
					data: fields[key],
					name: key,
					value: memberProps[key] || '',
				});
			}
		});
	}
};

const loadFieldFromCookie = (
	fieldName: Rfq.InlineFieldKeys,
	cookieData: Rfq.CookieData,
	fields: Rfq.InlineFields,
	callback: (field: InlineFieldShape, name: InlineFieldKeys) => void,
) => {
	if (!cookieData[fieldName]) {
		return;
	}

	const data = fields[fieldName];
	const value = cookieData[fieldName] || '';
	handleFieldChange({ cb: callback, data, name: fieldName, value });
};
